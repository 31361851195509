/*
 * Copyright 2020-2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */
import { get, includes, isEqual, isUndefined } from 'lodash';
import {
  BehaviorSubject,
  Observable,
  Subject,
  throwError
} from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { SessionModel } from './sessionModel';
import { Response } from 'node-fetch';
import { PersistentStateService } from './persistentStateService';

const REDIRECT_TO_COOKIE = 'redirect_to';

class SessionResponse extends Response {
  serverError = false;
  internalData: any = undefined;

  get data() {
    if (!this.internalData) {
      this.internalData = this.json();
    }
    return this.internalData;
  }

  set data(obj: any) {
    this.internalData = obj;
  }

  static from(responseBody: any): SessionResponse {
    const result = new SessionResponse();
    result.internalData = {};
    for (const prop in responseBody) {
      if (responseBody.hasOwnProperty(prop)) {
        result.internalData[prop] = responseBody[prop];
      }
    }
    result.serverError = false;
    return result;
  }
};

export class SessionService {
  static PARTNER_STATE = 'partner';

  REGEX_AZURE_B2C = /[#&]azureb2c=true(?:$|&)/;
  URI_SESSIONS_CURRENT = '/sessions/current';
  AZ_B2C_FORGOT_PASSWORD_POLICY = 'B2C_1A_forgot_password';
  AZ_B2C_CHANGE_PASSWORD_POLICY = 'B2C_1A_change_password';
  IGNORE_HUB_FEATURES_CDB_FLAG = 'ui.ignore.hub.features.cdb';
  LOGGED_OUT_HEADERS = {
    token: '',
    csrf: ''
  };
  win = window as any;
  hasPreflightRequestBeenQueued;
  lockedFeaturesByOwner: any;

  isLocalLogin$ = new BehaviorSubject(false);
  sessionNotify$ = new BehaviorSubject(null);

  authenticationError: any;
  loginError: any;
  loginEvent = new BehaviorSubject<boolean>(false);
  persistentStateService: PersistentStateService;
  private cache: any;
  loginObservable: Observable<any> | null;

  constructor(
  ) {
    this.hasPreflightRequestBeenQueued = false;
    this.persistentStateService = new PersistentStateService();
    this.loginObservable = null;
  }

  transformResponse(response: SessionResponse): SessionResponse {
    this.setAuthenticationError(false);
    if (!isUndefined(response)) {
      response.data = new SessionModel(this.getLocation().href, response.data);
      if (!response.data.isValid()) {
        response.serverError = true;
        throw response;
      }

      return response;
    }
    // eslint-disable-next-line no-throw-literal
    throw null;
  }

  getCache() {
    return this.cache;
  }

  hasContext(contexts: string[], context: string): boolean {
    if (!contexts || !contexts.length) {
      return context === 'customer';
    }
    return includes(contexts, context);
  }

  onLogin(resp: SessionResponse): SessionModel {
    const data = resp.data;
    this.persistentStateService.setSessionData(data);
    return data;
  }

  // After Jwt login, we have an id_token in the url hash which we don't need any more
  clearHash(data: any) {
    this.getLocation().hash = '';
    return data;
  }

  showError() {
    const errorParam = this.getLocation().hash.match(/[#&]error=([^&]*)/);
    if (errorParam !== null) {
      this.setAuthenticationError(true);
      switch (errorParam[1]) {
        case 'access_denied': {
          this.setLoginError('no_consent');
          break;
        }
        case 'server_error':
        case 'temporarily_unavailable': {
          this.setLoginError('temporarily_unavailable');
          break;
        }
        default: {
          this.setLoginError('config_error');
          break;
        }
      }
    } else {
      this.setAuthenticationError(false);
    }
  }

  cleanup() {
    this.showError();
    // TODO: This method is no longer required after page is fully refreshed on logout.
    // TODO: Ensure that handlers for this event are doing the right thing.
  }

  shouldRedirectToB2c(): boolean {
    const hash = this.getLocation().hash;
    return (hash.indexOf('id_token=') === -1 &&
      hash.indexOf('error=') === -1);
  }

  redirectToB2C() {
    /* eslint-disable */
    this.formB2CUrlAndRedirect();
    return true;
  }

  getLoginRoute() {
    return '/manage/central-login';
  }

  formB2CUrlAndRedirect(state: string | null = null) {
    this.getAndSaveRedirectTo();
    const url = encodeURIComponent(
      this.getHubHost() +
      this.getLoginRoute()
    );
    const pathname = this.getLocation().pathname;
    const clientId = this.getClientIdFromStateOrPath(pathname, state);
    const b2cUrl = this.win.AZ_B2C_SIGN_IN_URL;
    const b2cSignInPolicy = this.win.AZ_B2C_SIGN_IN_POLICY;
    const newUrl = b2cUrl +
      '?p=' +
      b2cSignInPolicy +
      '&client_id=' +
      clientId +
      '&redirect_uri=' +
      url +
      '&scope=openid&response_type=id_token' +
      this.getPromptParam(state) +
      '&' +
      this.getStateParam(state);
    this.suppressCheckSessions();
    setTimeout(() => {
      this.getLocation().href = newUrl;
    }, 0);
  }

  /**
   *  if state=partner then do not add '&prompt=login'.
   *  PDB logout gets the RedirectUrl from LogoutHandler in SOA,so it doesn't need '&prompt=login'
   *  CDB uses 401 detection and this method for both login and logout so it needs '&prompt=login'
   */
  getPromptParam(state: string | null) {
    if ((state && state === SessionService.PARTNER_STATE) ||
      (this.win.AUTH_TOKEN_SOURCE &&
        this.win.AUTH_TOKEN_SOURCE === SessionService.PARTNER_STATE)) {
      return '';
    }
    return '&prompt=login';
  }

  redirectToB2CLoginForPartner() {
    this.getLocation().href = this.win.PARTNER_DASHBOARD_REDIRECT_URL;
  }

  redirectToB2CPasswordReset(state: string | null) {
    const url = encodeURIComponent(
      this.getHubHost() +
      this.getLoginRoute()
    );
    const pathname = this.getLocation().pathname;
    const clientId = this.getClientIdFromStateOrPath(pathname, state);
    const b2cUrl = this.win.AZ_B2C_SIGN_IN_URL;
    const newUrl =
      b2cUrl +
      '?p=' +
      this.AZ_B2C_FORGOT_PASSWORD_POLICY +
      '&client_id=' +
      clientId +
      '&redirect_uri=' +
      url +
      '&scope=openid&response_type=id_token' + this.getPromptParam(state) +
      '&' +
      this.getStateParam(state);
    setTimeout(() => {
      this.getLocation().href = newUrl;
    }, 0);
  }

  getB2CChangePasswordUrl(state: string | null) {
    const url = encodeURIComponent(
      this.getHubHost() +
      this.getLoginRoute()
    );
    const pathname = this.getLocation().pathname;
    const clientId = this.getClientIdFromStateOrPath(pathname, state);
    const b2cUrl = this.win.AZ_B2C_SIGN_IN_URL;
    const newUrl =
      b2cUrl +
      '?p=' +
      this.AZ_B2C_CHANGE_PASSWORD_POLICY +
      '&client_id=' +
      clientId +
      '&redirect_uri=' +
      url +
      '&scope=openid&response_type=id_token&' +
      this.getStateParam(state);
    return newUrl;
  }

  /**
   * This method is used to map the inbound state param to subsequent state params that will be used
   * for the purpose of passing along in the X-TOKEN-SOURCE
   * @param state
   */
  getStateParam(state: string | null) {
    const stateParam = 'state=';
    if (state) {
      return stateParam + state;
    }
    if (this.win.AUTH_TOKEN_SOURCE) {
      return stateParam + this.win.AUTH_TOKEN_SOURCE;
    }
    return stateParam;
  }

  getClientIdFromStateOrPath(pathname: string, state: string | null) {
    if (pathname.indexOf('/partner') > -1) {
      return this.win.AZ_B2C_PARTNER_CLIENT_ID;
    }
    if (state && state === SessionService.PARTNER_STATE) {
      return this.win.AZ_B2C_PARTNER_CLIENT_ID;
    }
    return this.win.AZ_B2C_CLIENT_ID;
  }

  onAuthError(response: SessionResponse) {
    if (
      isEqual(response.status, 200) &&
      get(response, 'data.mfaRequired', false)
    ) {
      // If response 200 and 2FA enabled, mark user as logged in
      this.persistentStateService.setUserLoggedIn(true);
      // Do not display an authentication error if the user requires 2FA
      this.getLocation().assign('/mfa/validate');
    } else {
      this.setAuthenticationError(true);
      if (response.serverError) {
        this.setLoginError('server');
      } else {
        if (isEqual(response.status, 403)) {
          this.setLoginError('lockout');
        } else {
          this.setLoginError('client');
        }
      }
      return throwError(response);
    }
    return new Subject<never>();
  }

  onError(response: SessionResponse) {
    if (this.shouldSuppressCheckSessions()) {
      return new Subject();
    }

    this.cleanup();
    return throwError(response);
  }

  get() {
    if (this.shouldSuppressCheckSessions()) {
      return new Subject();
    }
    const posted = this.httpGetHelper(this.getHubPrefix() + this.URI_SESSIONS_CURRENT)
      .pipe(
        map((resp) => this.transformResponse(resp)),
        tap((resp) => (this.cache = resp.data)),
        map((resp) => this.onLogin(resp)),
        catchError((err) => this.onError(err))
      );
    const retval = new Subject();
    setTimeout(() => {
      posted.subscribe(retval);
    }, 0);
    return retval;
  }

  post(
    data = {}
  ) {
    return this.httpPostHelper(
      this.getHubPrefix() + this.URI_SESSIONS_CURRENT,
      data
    );
  }

  login(formData: any) {
    const headers = {
      Authorization:
        'Hammer ' +
        this.b64EncodeUnicode(formData.username + ':' + formData.password)
    };
    const url = this.getHubPrefix() + '/sessions';
    const posted = this.httpPostHelper(url, {}, headers).pipe(
      map((resp) => this.transformResponse(resp)),
      catchError((err) => this.onError(err)),
      tap((resp) => (this.cache = (resp as any).data)),
      map((resp) => this.onLogin(resp as SessionResponse)),
      catchError((err) => this.onAuthError(err))
    );
    const retval = new Subject();
    setTimeout(() => {
      posted.subscribe(retval);
    }, 0);
    return retval;
  }

  loginWithJwtToken(jwtToken: string, tokenSource: string) {
    // Log in passing a bearer token as the authorization, with the token source used to identify the issuer the token
    // needs to be validated for
    const headers = {
      Authorization: 'Bearer ' + jwtToken,
      'X-TOKEN-SOURCE': tokenSource
    };
    if (!this.loginObservable) {
      this.loginObservable =
        this.httpPostHelper(this.getHubPrefix() + '/sessions', {}, headers)
          .pipe(
            map((resp) => this.transformResponse(resp)),
            catchError((err) => this.onError(err)),
            tap((resp) => (this.cache = (resp as any).data)),
            map((resp) => this.onLogin(resp as SessionResponse)),
            map((resp) => this.clearHash(resp)),
            tap(() => {
              // This only runs for non-MFA users,
              // for MFA user, onAuthError is triggered from catchError
              this.loginEvent.next(true);
              this.persistentStateService.removeUserLoggedIn();
            }),
            catchError((err) => this.onAuthError(err)),
            take(1)
          );
    }
    return this.loginObservable;
  }

  b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    const replacer = (_: any, p1: string) => {
      return String.fromCharCode(parseInt(p1, 16));
    };
    return this.win.btoa(
      encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, replacer)
    );
  }

  /**
   * For unit tests to stub out... easiest way to test location changes while leaving
   * a reasonable runtime implementation
   */
  getLocation() {
    return this.win.location;
  }

  setAuthenticationError(value: boolean) {
    this.authenticationError = value;
  }

  setLoginError(msg: string) {
    this.loginError = msg;
  }

  /**
   * @returns This flag suppresses a race condition that
   * can happen with loading the azureB2C page
   * See CPUI-9082.
   */
  shouldSuppressCheckSessions() {
    return !!this.win.suppressSessionsCurrent;
  }

  suppressCheckSessions() {
    this.win.suppressSessionsCurrent = true;
  }

  httpGetHelper(url: string): Observable<SessionResponse> {
    const result = new Subject<SessionResponse>();
    fetch(url).then((resp) => {
      result.next(SessionResponse.from(resp as unknown as Response));
    });
    return result;
  }

  private includeSeparator(redirectPath: string) {
    if (redirectPath.length && redirectPath[redirectPath.length - 1] !== '?') {
      return '&';
    }
    return '';
  }

  getAndSaveRedirectTo() {
    let queryString = this.getLocation().search || '';
    const parts: string[] = decodeURIComponent(queryString).split('&');
    const redirectPath = parts.reduce((redirectPath, part) => {
      if (part.indexOf('?forwardTo=') > -1) {
        redirectPath += part.replace('?forwardTo=', '') + '?';
      } else {
        redirectPath += this.includeSeparator(redirectPath) + part;
      }
      return redirectPath.replace('?', '') === '/login' ? '' : redirectPath;
    }, '');
    this.setRedirectTo(redirectPath);
  }
  
  httpPostHelper(url: string, body: any, headers: any = null): Observable<SessionResponse> {
    const payload: any = {};
    payload.method = 'POST';
    payload.body = body;
    if (headers) {
      payload.headers = headers;
    }
    const result = new Subject<SessionResponse>();
    fetch(url, payload).then((resp) => {
      if (resp.status > 299) {
        result.error(resp);
      } else {
        const json = resp.json();
        if (json) {
          json.then((body) => {
            const sessionResponse = SessionResponse.from(body);
            result.next(sessionResponse);
          });
        } else {
          result.next(SessionResponse.from({}));
        }
      }
    });
    return result;
  }

  /**
   * Save the part of the URL we want to go to in a cookie so that we can redirect there after login
   *
   * @param path an uri-component-escaped portion of the path to forward to, including everything
   * following https://hostname/manage
   * Example: If we really want to forward to https://hostname/manage/threat-analysis-center/dashboard
   * we would receive the string '%2Fthreat-analysis-center%2Fdashboard and store the value
   * `/threat-analysis-center/dashboard` in the cookie
   */
  setRedirectTo(path: string) {
    document.cookie = `${REDIRECT_TO_COOKIE}=${path}; domain=${this.getCookieDomain()}; path=/; secure=true`;
  }

  getHubHost() {
    return location.protocol + '//' + location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  getHubPrefix() {
    return this.getHubHost() + '/api';
  }

  getCookieDomain() {
    const hostNameAsArray = (window as any).location.hostname.split('.');
    const lastTwoDotPieces = hostNameAsArray.splice(-2).join('.');
    return lastTwoDotPieces; // sandbox.sophos or sophos.com or sophos.us or whatever
  }

}

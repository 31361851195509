/**
 * Copyright 2020-2022 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

import { assign, every, get, reduce } from 'lodash';

/**
 * The SessionModel is created by hub-services/src/main/java/com/sophos/cloud/service/impl/hub/HubAuthServiceImpl.java
 * The backend does not define a class but populates a Map<String, ?>
 * From that code, we determine that the model contains the following
 * properties. When the backend changes, we should change this model.
 */
export class SessionModel {
  static readonly REQUIRED_KEYS = ['csrf', 'token'];
  static readonly PARTNER = 'partner';
  static readonly PARTNER_PATH = '/partner';
  static readonly SUPPORT_PORTAL = 'supportPortal';
  static readonly SUPPORT_PORTAL_PARTNER_STATE = 'partnerSupport';
  static readonly LIGHTNING_PARTNER_STATE = 'partnerLightning';

  accountId: string;
  accountOwner: any;
  apis: any;
  capabilities: any;
  contexts: string[];
  csrf: string; // this will probably be deprecated by jwt
  currentHref: string;  // not supplied by backend, but by browser
  customerEstateId: string;
  customerId: string;
  features: any[];
  flags: { [key: string]: boolean };
  gaAccount: string;
  hubAsset: string;
  launchDarkly: any;
  lockedFeaturesByOwner: any;
  marketplaceStatus: any;
  mfaRequired: boolean;
  partnerId: string;
  permissions: { [key: string]: boolean };
  region: string;
  supportedGlobalPolicies: { [key: string]: string[] };
  token: string;  // this will be deprecated by jwt project
  userType: string;
  isGreenfield: string;

  constructor(currentHref: string, data: any) {
    assign(this, data);
    this.accountId = data.account_id;
    this.accountOwner = data.accountOwner;
    this.apis = data.apis;
    this.capabilities = data.capabilities;
    this.contexts = data.contexts;
    this.currentHref = currentHref;
    this.csrf = data.csrf;
    this.customerEstateId = data.customerEstateId;
    this.customerId = data.customer_id;
    this.features = data.features;
    this.flags = data.flags;
    this.gaAccount = data.gaaccount;
    this.hubAsset = data.hub_asset;
    this.launchDarkly = data.launchDarkly;
    this.lockedFeaturesByOwner = data.lockedFeaturesByOwner;
    this.marketplaceStatus = data.marketplaceStatus;
    this.mfaRequired = data.mfa_required;
    this.partnerId = data.partnerId;
    this.permissions = data.permissions;
    this.region = data.region;
    this.supportedGlobalPolicies = data.supportedGlobalPolicies;
    this.token = data.token;
    this.userType = data.userType;
    this.isGreenfield = data.isGreenfield;

    this.features = this.features || [];
    if (data.accountOwner && data.accountOwner.features) {
      data.accountOwner.features = data.accountOwner.features || [];
      this.accountOwner = data.accountOwner;
    }

    this.permissions = reduce((this.permissions || []), (result, p) => {
      const p0: string = (p as unknown as string);
      (result as any)[p0] = true;
      return result;
    }, {});
  }

  /**
   * method checks to see if the passed in href contains the /partner path or if it contains a
   * state=x param that indicates that we are attempting to access a partner dashboard via central/common page
   * @param href - current href that should be parsed
   * @return true if the url or state param contains valid notion of partner, false otherwise
   * @private
   */
  private static isPartnerUrlOrState(href: string): boolean {

    if(href.indexOf(SessionModel.PARTNER_PATH) > -1){
      return true;
    }

    const stateRegex = /(?:&|#)state\=([^&]*)/;
    const state: RegExpMatchArray | null = href.match(stateRegex);
    const allowedPartnerStates = [
      SessionModel.PARTNER, SessionModel.SUPPORT_PORTAL_PARTNER_STATE, SessionModel.LIGHTNING_PARTNER_STATE
    ];

    return (state !== null) && state.length === 2 && allowedPartnerStates.indexOf(state[1]) > -1;
  }

  isValid(): boolean {
    // CPUI-4987: if we launch a customer from the partner ui and log out of the customer
    // the backend still gives us a successful response to /sessions/current
    // which contains a context of partner only and even has a hammer token for the
    // partner session (but not for a customer session).
    // if we are in the customer tab but have only partner auth, treat it as invalid
    // for this session.
    if(this.contexts) {
      const clonedContexts = this.removeSupportPortalContext();

      // CPLAT-45401 - This was done as a special case as to avoid affecting other unknown cases where the
      // contexts.length could include partner and be greater than 1. For example, its unknown if there can be a
      // darkbytes context or such. Alternatively, the following check if this.contexts contains 'partner' but this
      // would potentially have a greater impact, but be more robust.
      if (clonedContexts.length === 1 && clonedContexts[0] === SessionModel.PARTNER && !SessionModel.isPartnerUrlOrState(this.currentHref)) {
        return false;
      }
    }

    return every(SessionModel.REQUIRED_KEYS, (key) => {
      return get(this, key);
    });
  }

  private removeSupportPortalContext(){
    // clones the contexts array as to not change it at a class level and then returns new array with support portal removed
    const clonedContexts = Object.assign([], this.contexts);
    const supportPortalIndex = clonedContexts.indexOf(SessionModel.SUPPORT_PORTAL);
    if(supportPortalIndex > -1) {
      clonedContexts.splice(supportPortalIndex, 1);
    }
    return clonedContexts;
  }
}

/**
 * Copyright 2021 - 2024 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

import { isNil, isUndefined } from 'lodash';

export class PersistentStateService {
  public clearBrowserStorage() {
    window.sessionStorage.clear();
  }

  // START: userLoggedIn
  public getUserLoggedIn(): any {
    return this.getData('userLoggedIn');
  }

  public setUserLoggedIn(value: any) {
    this.setData('userLoggedIn', value);
  }

  public removeUserLoggedIn() {
    this.removeData('userLoggedIn');
  }
  // END: userLoggedIn

  // START SESSION DATA FOR SINGLE SPA
  public getSessionData(): any {
    return this.getData('sharedSessionData');
  }

  public setSessionData(value: any) {
    this.setData('sharedSessionData', value);
  }

  public removeSessionData() {
    this.removeData('sharedSessionData');
  }
  // END SESSION DATA FOR SINGLE SPA

  // START BANNER DISMISSAL DATA
  public getBannersDismissed(): any {
    return this.getData('banners_dismissed');
  }

  public setBannersDismissed(value: string) {
    const data = this.getBannersDismissed() || [];
    data.push(value);
    this.setData('banners_dismissed', data);
  }

  public restartBannerSession() {
    this.setData('banners_dismissed', []);
  }
  // END BANNER DISMISSAL DATA

  // START: KeyValue
  public getKeyValue(key: string): string {
    return this.getData(key, false);
  }

  public setKeyValue(key: string, value: string) {
    this.setData(key, value, false);
  }

  public removeKeyValue(key: string) {
    this.removeData(key, true);
  }
  // END: KeyValue

  /**
   * Set data to local prop and session storage
   * @param key Key to the data
   * @param value Value to be set, this value is strigified
   */
  private setData(key: string, value: any, canStringify = true): void {
    const valueToSave = canStringify ? JSON.stringify(value) : value;
    // before writing data to Session Storage, populate local
    // prop with the value
    (this as any)[key] = valueToSave;
    window.sessionStorage.setItem(key, valueToSave);
  }

  /**
   * First check the local prop for value, if found, returns it. If not,
   * attempt to get from session storage, if undefined, return undefined
   * else, store the value to local prop and return the parsed data.
   * @param key Key to the data
   * @returns Parsed data or undefined
   */
  private getData(key: string, canParse = true): any | undefined {
    // before getting data from Session Storage, try fetching from local prop
    let value = (this as any)[key];
    if (!isUndefined(value)) {
      return canParse ? JSON.parse(value) : value;
    }
    // now attempt to get data from Session Storage
    value = window.sessionStorage.getItem(key);
    if (isNil(value)) {
      return;
    }
    // populate local prop before returning
    (this as any)[key] = value;
    return canParse ? JSON.parse(value) : value;
  }

  /**
   * Remove data from Session Storage
   * @param key Key to the data which needs to be removed
   */
  private removeData(key: string, removeLocalProp = false) {
    if (removeLocalProp) {
      delete (this as any)[key];
    }
    window.sessionStorage.removeItem(key);
  }
}

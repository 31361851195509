/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

/* eslint-disable brace-style */
/* eslint-disable @typescript-eslint/semi */

import React from 'react';
import AzureReturnHandler from './AzureReturnHandler';
import { SessionService } from './sessionService';
import { Translations } from './translations';

export const Login = () => {
  const sessionService = new SessionService();
  const t = new Translations();
  if (sessionService.shouldRedirectToB2c()) {
    sessionService.formB2CUrlAndRedirect();
    return (
      <div className="b2c-login login-waiting">{ t.get('login.messages.error.authentication.no_token') }</div>
    )
  }
  else {
    return (
      <div className="b2c-login">
        <AzureReturnHandler />
      </div>
    );
  }
}

export default Login;

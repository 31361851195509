/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

import React from 'react';
import { Translations } from './translations';
import { SessionService } from './sessionService';

function redirectToB2C() {
  const sessionService = new SessionService();
  sessionService.redirectToB2C();
}

function LoginError(props: any) {
  const errorText: string = props.errorText;
  const loginResult: string = props.loginResult;
  const translations = new Translations();
  return (
    <>
      <div className="login-error">
        <div className="error-message">
            {
              loginResult === 'no_account' && translations.get('login.messages.error.authentication.central.no_account')
            }
            {
              loginResult !== 'no_account' && !errorText &&
              <span dangerouslySetInnerHTML={{__html: translations.get('login.messages.error.authentication.' + loginResult)}}></span>
            }
            {
              loginResult !== 'no_account' && errorText
            }
        </div>
        <div className="login-link">
            <span dangerouslySetInnerHTML={{__html: translations.get('customer.b2c-login.messages.error.retry.link')}}>
            </span>
        </div>
      </div>
    </>
  );
};

export default LoginError;

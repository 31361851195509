/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

// NOTE TO REVIEWERS - this file is a copy/paste of a file from libs/shared
// but as part of a series of PRs, we expect to remove this from libs/shared later
// so the duplication is only temporary

export const SESSION_LIMIT_EXCEEDED_ERROR = 'session_limit_exceeded_error';
export const UNAUTHORIZED = 'unauthorized';
export const DEFAULT_AUTHORIZATION_ERROR = 'default_authorization_error';
export const TOO_MANY_SESSIONS = 'TOO_MANY_SESSIONS';

/* eslint-disable max-len */
/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

export class Translations {
  static COOKIE_KEY = 'SC_USER_LANG';
  static LOCALES: any = {
    'en': 'en-us',
    'de': 'de-de',
    'es': 'es-es',
    'fr': 'fr-fr',
    'ja': 'ja-jp',
    'zh': 'zh-tw',
    'it': 'it-it',
    'pt': 'pt-br',
    'ko': 'ko-kr'
  };

  /**
   * For CDB/PDB/EDB we have enormous localization bundles. For this simple login app,
   * we store all the localizations for all supported languages here in the app itself
   * so there is no additional time to fetch strings and to wait until localizations
   * are "ready".
   */
  static TRANSLATIONS: any = {
    'en-us': {
      'customer.b2c-login.messages.error.retry.link': 'Please <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">click here</a> to sign in again.',
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. All rights reserved.',
      'login.messages.error.authentication.no_error': 'One moment<br/> while we sign you in...',
      'login.messages.error.authentication.no_token': 'Please wait while we redirect you to sign in.',
      'login.messages.error.authentication.bad_token': 'Sign-in timed out. Please try to sign in again.',
      'login.messages.error.authentication.client': '<strong>Authentication failed.</strong> Please check your credentials and try again.',
      'login.messages.error.authentication.config_error': 'Sign-in with Microsoft is unavailable.',
      'login.messages.error.authentication.lockout': 'You have exceeded the maximum number of allowed tries. Your account has been locked for the next 5 minutes.',
      'login.messages.error.authentication.no_account': 'You don\'t have a Sophos account.',
      'login.messages.error.authentication.central.no_account': 'Login not allowed access to this Account',
      'login.messages.error.authentication.no_consent': 'You can\'t sign in with Microsoft. An Azure AD admin or a user must consent first.',
      'login.messages.error.authentication.not_federated': 'You\'re not allowed to sign in with Microsoft. Please contact your Sophos Central account admin.',
      'login.messages.error.authentication.server': 'Authentication failed due to server error.',
      'login.messages.error.authentication.temporarily_unavailable': 'Sign-in with Microsoft is temporarily unavailable. Please try again.',
      'login.messages.error.authentication.password_reset': 'Please wait ...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Please wait ...',
      'login.messages.error.authentication.unauthorized': 'You are not authorized to access this service.',
      'login.messages.error.authentication.default_authorization_error': 'Unable to access central. Did you mean to access self service portal ?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'You have reached the maximum number of allowed sessions for your account. To continue using this service, please log out of one of your existing sessions and try again. If you believe this message is in error, please contact customer support for assistance.',
      'login.messages.error.retry.link': 'Please <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">click here</a> to sign in again.',
      'login.messages.success.forward': 'You have logged into Sophos Central... please wait while we forward to the application'
    },
    'de-de': {
      "customer.b2c-login.messages.error.retry.link": "Bitte <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">klicken Sie hier</a>, um sich erneut anzumelden.",
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. Alle Rechte vorbehalten.',
      'login.messages.error.authentication.no_error': 'Einen Augenblick,<br/> wir melden Sie gerade an ...',
      'login.messages.error.authentication.no_token': 'Bitte warten Sie, während wir Sie zur Anmeldung weiterleiten.',
      'login.messages.error.authentication.bad_token': 'Zeitüberschreitung bei der Anmeldung Bitte versuchen Sie sich erneut anzumelden.',
      'login.messages.error.authentication.client': '<strong>Authentifizierung fehlgeschlagen.</strong> Überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut.',
      'login.messages.error.authentication.config_error': 'Anmeldung mit Microsoft ist nicht verfügbar.',
      'login.messages.error.authentication.lockout': 'Sie haben die maximal erlaubte Anzahl an Versuchen überschritten. Ihr Konto ist für die nächsten 5 Minuten gesperrt.',
      'login.messages.error.authentication.no_account': 'Sie haben kein Sophos-Konto.',
      'login.messages.error.authentication.central.no_account': 'Zugriff auf dieses Konto ist mit diesen Anmeldedaten nicht erlaubt',
      'login.messages.error.authentication.no_consent': 'Sie können sich nicht mit Microsoft anmelden. Ein Azure AD-Administrator oder Benutzer muss zunächst zustimmen.',
      'login.messages.error.authentication.not_federated': 'Sie sind nicht zur Anmeldung mit Microsoft berechtigt. Bitte wenden Sie sich an Ihren Sophos Central-Kontoadministratoren.',
      'login.messages.error.authentication.server': 'Authentifizierung aufgrund eines Serverfehlers fehlgeschlagen.',
      'login.messages.error.authentication.temporarily_unavailable': 'Anmeldung mit Microsoft ist vorübergehend nicht verfügbar. Bitte versuchen Sie es erneut.',
      'login.messages.error.authentication.password_reset': 'Bitte warten Sie ...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Bitte warten Sie ...',
      'login.messages.error.authentication.unauthorized': 'Sie sind nicht berechtigt, auf diesen Dienst zuzugreifen.',
      'login.messages.error.authentication.default_authorization_error': 'Kein Zugriff auf Sophos Central. Wollten Sie das Self Service Portal öffnen?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'Sie haben für Ihr Konto die maximal erlaubte Anzahl an Sitzungen erreicht. Um diesen Dienst weiter zu verwenden, melden Sie sich aus einer Ihrer vorhandenen Sitzungen ab und versuchen es erneut. Wenn Sie glauben, dass diese Information falsch ist, wenden Sie sich bitte an den Kunden-Support.',
      'login.messages.error.retry.link': 'Bitte <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">klicken Sie hier</a> um sich erneut anzumelden.',
      'login.messages.success.forward': 'Sie haben sich an Sophos Central angemeldet. Sie werden jetzt zur Anwendung weitergeleitet...'
    },
    'es-es': {
      "customer.b2c-login.messages.error.retry.link": "Haga <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">clic aquí</a> para iniciar sesión de nuevo.",
      'copyright-year': '\u00a9 2013 - 2023 Sophos Limited. Todos los derechos reservados.',
      'login.messages.error.authentication.no_error': 'Espere un momento<br/> mientras inicia sesión...',
      'login.messages.error.authentication.no_token': 'Espere mientras le redirigimos para iniciar sesión.',
      'login.messages.error.authentication.bad_token': 'Se ha agotado el tiempo de espera del inicio de sesión. Intente iniciar sesión de nuevo.',
      'login.messages.error.authentication.client': '<strong>No se ha podido realizar la autenticación.</strong> Revise sus credenciales e inténtelo de nuevo.',
      'login.messages.error.authentication.config_error': 'El inicio de sesión con Microsoft no está disponible.',
      'login.messages.error.authentication.lockout': 'Ha superado el número máximo de intentos permitidos. Su cuenta se ha bloqueado durante los próximos 5 minutos.',
      'login.messages.error.authentication.no_account': 'No dispone de una cuenta de Sophos.',
      'login.messages.error.authentication.central.no_account': 'El inicio de sesión no tiene acceso a esta cuenta',
      'login.messages.error.authentication.no_consent': 'No puede iniciar sesión con Microsoft. Un administrador de Azure AD o un usuario debe dar su consentimiento primero.',
      'login.messages.error.authentication.not_federated': 'No puede iniciar sesión con Microsoft. Póngase en contacto con el administrador de su cuenta de Sophos Central.',
      'login.messages.error.authentication.server': 'No se ha podido realizar la autenticación debido a un error del servidor.',
      'login.messages.error.authentication.temporarily_unavailable': 'El inicio de sesión con Microsoft no está disponible temporalmente. Inténtelo de nuevo.',
      'login.messages.error.authentication.password_reset': 'Espere...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Espere...',
      'login.messages.error.authentication.unauthorized': 'No está autorizado a acceder a este servicio.',
      'login.messages.error.authentication.default_authorization_error': 'No se puede acceder a Central. ¿Quería acceder a Self Service Portal?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'Ha alcanzado el número máximo de sesiones permitidas para su cuenta. Para seguir utilizando este servicio, cierre una de sus actuales sesiones e inténtelo de nuevo. Si cree que ha recibido este mensaje por error, póngase en contacto con atención al cliente para obtener asistencia.',
      'login.messages.error.retry.link': '<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">Haga clic aquí</a> para volver a iniciar sesión.',
      'login.messages.success.forward': 'Ha iniciado sesión en Sophos Central... Espere mientras le reenviamos a la aplicación'
    },
    'fr-fr': {
      "customer.b2c-login.messages.error.retry.link": "Veuillez <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">cliquer ici</a> pour vous reconnecter.",
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. Tous droits réservés.',
      'login.messages.error.authentication.no_error': 'Veuillez patienter quelques instants<br/> pendant que nous établissons votre connexion...',
      'login.messages.error.authentication.no_token': 'Veuillez patienter pendant que nous vous redirigeons vers la page de connexion.',
      'login.messages.error.authentication.bad_token': 'Délai d’attente de connexion dépassé Veuillez essayer de vous reconnecter.',
      'login.messages.error.authentication.client': '<strong>Authentification impossible.</strong> Veuillez vérifier vos codes d’accès et réessayer.',
      'login.messages.error.authentication.config_error': 'Connexion avec Microsoft indisponible.',
      'login.messages.error.authentication.lockout': 'Vous avez atteint le nombre maximum de tentatives autorisées. Votre compte sera verrouillé pendant les 5 prochaines minutes.',
      'login.messages.error.authentication.no_account': 'Vous n’avez pas de compte Sophos.',
      'login.messages.error.authentication.central.no_account': 'Connexion non autorisée pour ce compte',
      'login.messages.error.authentication.no_consent': 'Vous ne pouvez pas vous connecter avec Microsoft. Un administrateur ou utilisateur Azure AD doit d’abord donner son accord.',
      'login.messages.error.authentication.not_federated': 'Vous n’êtes pas autorisé à vous connecter avec Microsoft. Veuillez contacter l’administrateur de votre compte Sophos Central.',
      'login.messages.error.authentication.server': 'Authentification impossible en raison d’une erreur du serveur.',
      'login.messages.error.authentication.temporarily_unavailable': 'Connexion avec Microsoft temporairement indisponible. Veuillez réessayer.',
      'login.messages.error.authentication.password_reset': 'Veuillez patienter...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Veuillez patienter...',
      'login.messages.error.authentication.unauthorized': 'Vous n’êtes pas autorisé à accéder à ce service.',
      'login.messages.error.authentication.default_authorization_error': 'Impossible d’accéder à Central. Vouliez-vous accéder au portail libre-service ?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'Vous avez atteint le nombre maximal de sessions autorisées pour votre compte. Pour continuer à utiliser ce service, déconnectez-vous de l’une de vos sessions en cours et réessayez. Si vous pensez avoir reçu ce message par erreur, veuillez contacter le support client pour obtenir de l’aide.',
      'login.messages.error.retry.link': 'Veuillez <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">cliquer ici</a> pour vous reconnecter.',
      'login.messages.success.forward': 'Vous êtes connecté à Sophos Central... Veuillez patienter pendant que nous transférons l’application'
    },
    'ja-jp': {
      "customer.b2c-login.messages.error.retry.link": "<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">こちらをクリック</a>して、もう一度サインインしてください。",
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. All rights reserved.',
      'login.messages.error.authentication.no_error': 'しばらくお待ちください<br/>サインインしています...',
      'login.messages.error.authentication.no_token': 'サインインできるようにするため、リダイレクトしています。しばらくお待ちください。',
      'login.messages.error.authentication.bad_token': 'サインインがタイムアウトしました。もう一度サインインしなおしてください。',
      'login.messages.error.authentication.client': '<strong>認証に失敗しました。</strong>入力内容を確認して、もう一度やり直してください。',
      'login.messages.error.authentication.config_error': 'Microsoft でのサインインを使用できません。',
      'login.messages.error.authentication.lockout': '許可されている試行回数の上限を超えました。アカウントは、5分間ロックされます。',
      'login.messages.error.authentication.no_account': 'ソフォスのアカウントがありません',
      'login.messages.error.authentication.central.no_account': 'このアカウントへのアクセスは許可されていません',
      'login.messages.error.authentication.no_consent': 'Microsoft でサインインすることはできません。Azure AD 管理者またはユーザーの同意が必要です。',
      'login.messages.error.authentication.not_federated': 'Microsoft でのサインインは許可されていません。Sophos Central アカウントの管理者にお問い合わせください。',
      'login.messages.error.authentication.server': 'サーバーのエラーが原因で認証に失敗しました。',
      'login.messages.error.authentication.temporarily_unavailable': 'Microsoft でのサインインは一時的に使用できません。もう一度やり直してください。',
      'login.messages.error.authentication.password_reset': 'お待ちください...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'お待ちください...',
      'login.messages.error.authentication.unauthorized': 'このサービスにアクセスする権限がありません。',
      'login.messages.error.authentication.default_authorization_error': 'Sophos Central にアクセスできません。Self Service Portal にアクセスするつもりでしたか？',
      'login.messages.error.authentication.session_limit_exceeded_error': 'アカウントで許可されている最大セッション数に達しました。このサービスの使用を続行するには、既存のセッションのいずれかからログアウトし、もう一度やり直してください。このメッセージが間違っていると思われる場合は、カスタマーサポートにお問い合わせください。',
      'login.messages.error.retry.link': '<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">こちらをクリック</a>して、もう一度サインインしてください。',
      'login.messages.success.forward': 'Sophos Central にログインしました。アプリケーションに転送するまでお待ちください'
    },
    'zh-tw': {
      "customer.b2c-login.messages.error.retry.link": "請<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">按一下這裡</a>以再次登入。",
      'copyright-year': '\u00a9 2013 - {{ year }} Sophos Limited.保留一切權利。',
      'login.messages.error.authentication.no_error': '正將您登入，請稍候<br/>...',
      'login.messages.error.authentication.no_token': '請稍候，我們將為您重新導向以登入。',
      'login.messages.error.authentication.bad_token': '登入超時。請重新登入。',
      'login.messages.error.authentication.client': '<strong>驗證失敗。</strong>請檢查您的憑證，然後重試。',
      'login.messages.error.authentication.config_error': '不可使用 Microsoft 登入。',
      'login.messages.error.authentication.lockout': '您已超過允許的嘗試次數上限。您的帳戶在接下來 5 分鐘內已被鎖定。',
      'login.messages.error.authentication.no_account': '您沒有 Sophos 賬戶。',
      'login.messages.error.authentication.central.no_account': '不允許登入存取該帳戶',
      'login.messages.error.authentication.no_consent': '您不能使用 Microsoft 登入。Azure AD 管理員或使用者必須先同意。',
      'login.messages.error.authentication.not_federated': '您不能使用 Microsoft 登入。請聯絡您的 Sophos Central 帳戶管理員。',
      'login.messages.error.authentication.server': '驗證因伺服器錯誤而失敗。',
      'login.messages.error.authentication.temporarily_unavailable': '暫時不可使用 Microsoft 登入。請重試。',
      'login.messages.error.authentication.password_reset': '請稍候 ...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': '請稍候 ...',
      'login.messages.error.authentication.unauthorized': '您未獲得此項服務的存取權。',
      'login.messages.error.authentication.default_authorization_error': '無法存取 Central。您是要存取自助服務入口網站嗎？',
      'login.messages.error.authentication.session_limit_exceeded_error': '您已達到帳戶允許的最大工作階段數。要繼續使用此服務，請登出現有的工作階段之一，然後重試。如果您認為此訊息有誤，請聯絡客戶支援以取得協助。',
      'login.messages.error.retry.link': '請<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">按一下這裡</a>重新登入。',
      'login.messages.success.forward': '您已登入 Sophos Central...正在轉接至應用程式，請稍後'
    },
    'it-it': {
      "customer.b2c-login.messages.error.retry.link": "Clicca <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">qui</a> per effettuare di nuovo l'accesso.",
      'copyright-year': '\u00a9 2013 - {{ year }} Sophos Limited. Tutti i diritti riservati.',
      'login.messages.error.authentication.no_error': 'Attendere <br/> mentre elaboriamo la richiesta di accesso...',
      'login.messages.error.authentication.no_token': 'Attendere mentre si è reindirizzati per effettuare l\'accesso.',
      'login.messages.error.authentication.bad_token': 'Timeout dell\'accesso. Si prega di riprovare a effettuare l\'accesso.',
      'login.messages.error.authentication.client': '<strong>Autenticazione non riuscita.</strong> Controlla le tue credenziali e riprova.',
      'login.messages.error.authentication.config_error': 'Accesso con Microsoft non disponibile.',
      'login.messages.error.authentication.lockout': 'Hai superato il numero massimo di tentativi consentiti. L\'account risulterà bloccato per i prossimi 5 minuti.',
      'login.messages.error.authentication.no_account': 'Non si dispone di un account Sophos.',
      'login.messages.error.authentication.central.no_account': 'Credenziali non autorizzate per l\'accesso a questo account',
      'login.messages.error.authentication.no_consent': 'Non è possibile accedere con Microsoft. Occorre prima il consenso di un amministratore o utente di Azure AD.',
      'login.messages.error.authentication.not_federated': 'Accesso con Microsoft non autorizzato. Contattare l\'amministratore del proprio account Sophos Central.',
      'login.messages.error.authentication.server': 'Autenticazione non riuscita a causa di un errore del server.',
      'login.messages.error.authentication.temporarily_unavailable': 'L\'accesso con Microsoft è temporaneamente non disponibile. Riprova.',
      'login.messages.error.authentication.password_reset': 'Attendere...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Attendere...',
      'login.messages.error.authentication.unauthorized': 'Non hai l’autorizzazione necessaria per accedere a questo servizio.',
      'login.messages.error.authentication.default_authorization_error': 'Impossibile accedere a Central. Volevi accedere al Self Service Portal?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'Hai raggiunto il numero massimo di sessioni consentite per il tuo account. Per continuare a utilizzare questo servizio, accedi a una delle tue sessioni già attive e riprova. Se pensi che ci sia stato un errore, contatta il supporto clienti.',
      'login.messages.error.retry.link': '<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">Cliccare qui</a> per riprovare a effettuare l\'accesso.',
      'login.messages.success.forward': 'Hai effettuato l’accesso a Sophos Central... Per favore, attendi il reindirizzamento all’applicazione'
    },
    'pt-br': {
      "customer.b2c-login.messages.error.retry.link": "<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">Clique aqui</a> para conectar-se novamente.",
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. Todos os direitos reservados.',
      'login.messages.error.authentication.no_error': 'Aguarde<br/> enquanto o conectamos à sessão...',
      'login.messages.error.authentication.no_token': 'Aguarde enquanto o redirecionamos para iniciar uma sessão.',
      'login.messages.error.authentication.bad_token': 'O limite de tempo de conexão expirou. Tente se conectar outra vez.',
      'login.messages.error.authentication.client': '<strong>Falha na autenticação.</strong> Verifique suas credenciais e tente novamente.',
      'login.messages.error.authentication.config_error': 'A conexão com Microsoft não está disponível.',
      'login.messages.error.authentication.lockout': 'Você ultrapassou o número máximo de tentativas permitidas. Sua conta foi bloqueada pelos próximos 5 minutos.',
      'login.messages.error.authentication.no_account': 'Você não tem uma conta Sophos.',
      'login.messages.error.authentication.central.no_account': 'O login não permitiu acesso a esta Conta',
      'login.messages.error.authentication.no_consent': 'Você não pode se conectar com Microsoft. Um usuário ou um admin do Azure AD precisa dar o consentimento primeiro.',
      'login.messages.error.authentication.not_federated': 'Você não está autorizado a se conectar com Microsoft. Contate o administrador da sua conta do Sophos Central.',
      'login.messages.error.authentication.server': 'Falha na autenticação devido a um erro de servidor.',
      'login.messages.error.authentication.temporarily_unavailable': 'A conexão com Microsoft está temporariamente indisponível. Tente novamente.',
      'login.messages.error.authentication.password_reset': 'Por favor, aguarde ...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': 'Por favor, aguarde ...',
      'login.messages.error.authentication.unauthorized': 'Você não tem autorização para acessar este serviço.',
      'login.messages.error.authentication.default_authorization_error': 'Não é possível acessar o Central. Você pretendia acessar o Portal de Autoatendimento?',
      'login.messages.error.authentication.session_limit_exceeded_error': 'Você atingiu o número máximo de sessões permitidas para a sua conta. Para continuar usando o serviço, desconecte-se de uma de suas sessões existentes e tente novamente. Se você acredita que essa mensagem tenha chegado a você por engano, contate o administrador da sua conta.',
      'login.messages.error.retry.link': '<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">Clique aqui</a> para conectar-se novamente.',
      'login.messages.success.forward': 'Você fez login no Sophos Central… Aguarde enquanto é encaminhado ao aplicativo'
    },
    'ko-kr': {
      "customer.b2c-login.messages.error.retry.link": "다시 로그인하려면 <a onclick=\"window.location.href=window.location.origin+window.location.pathname\">여기를 클릭</a>하십시오.",
      'copyright-year': '\u00a9 1997 - {{ year }} Sophos Limited. All rights reserved.',
      'login.messages.error.authentication.no_error': '로그인하는 동안<br/> 잠시 기다려 주십시오...',
      'login.messages.error.authentication.no_token': '로그인하도록 리디렉션하는 동안 기다려 주십시오.',
      'login.messages.error.authentication.bad_token': '로그인 시간이 초과되었습니다. 다시 로그인을 시도하십시오.',
      'login.messages.error.authentication.client': '<strong>인증에 실패했습니다.</strong> 자격 증명을 확인한 다음 다시 시도하십시오.',
      'login.messages.error.authentication.config_error': 'Microsoft를 사용한 로그인을 사용할 수 없습니다.',
      'login.messages.error.authentication.lockout': '허용되는 최대 시도 횟수를 초과했습니다. 다음 5분 동안 계정이 잠깁니다.',
      'login.messages.error.authentication.no_account': 'Sophos 계정이 없습니다.',
      'login.messages.error.authentication.central.no_account': '로그인은 이 계정에 대한 액세스를 허용하지 않음',
      'login.messages.error.authentication.no_consent': 'Microsoft로 로그인할 수 없습니다. Azure AD 관리자 또는 사용자가 먼저 동의해야 합니다.',
      'login.messages.error.authentication.not_federated': 'Microsoft를 사용한 로그인이 허용되지 않습니다. Sophos Central 계정 관리자에게 문의하십시오.',
      'login.messages.error.authentication.server': '서버 오류로 인해 인증이 실패했습니다.',
      'login.messages.error.authentication.temporarily_unavailable': 'Microsoft를 사용한 로그인을 일시적으로 사용할 수 없습니다. 다시 시도하십시오.',
      'login.messages.error.authentication.password_reset': '잠시 기다려 주십시오...',
      'login.messages.error.authentication.user_cancelled_entering_self_asserted_info': '잠시 기다려 주십시오...',
      'login.messages.error.authentication.unauthorized': '이 서비스에 액세스할 수 있는 권한이 없습니다.',
      'login.messages.error.authentication.default_authorization_error': 'Central에 액세스할 수 없습니다. Self Service Portal에 대한 액세스를 계속하시겠습니까?',
      'login.messages.error.authentication.session_limit_exceeded_error': '귀하의 계정에 허용된 최대 세션 수에 도달했습니다. 이 서비스를 계속 사용하려면 기존 세션 중 하나에서 로그아웃한 후 다시 시도하십시오. 이 메시지에 오류가 있다고 생각되면 고객 지원팀에 문의하여 도움을 받으십시오.',
      'login.messages.error.retry.link': '<a onclick=\"window.location.href=window.location.origin+window.location.pathname\">여기를 클릭</a>하여 다시 로그인하십시오.',
      'login.messages.success.forward': 'Sophos Central에 로그인했습니다... 응용 프로그램을 전달하는 동안 잠시 기다려 주십시오.'
    }
  };

  lang: string;
  translation;

  constructor() {
    const cookies = document.cookie.split('; ');
    this.lang = null;
    if (cookies && cookies.length) {
      cookies.forEach((cookie) => {
        const [key, value] = cookie.split('=');
        if (key === Translations.COOKIE_KEY) {
          this.lang = value;
        }
      });
    }
    if (!this.lang) {
      this.lang = (window as any).LANGUAGE;
    }
    if (!this.lang) {
      this.lang = navigator.languages[0].toLowerCase();
    }
    if (this.lang.length === 2) {
      this.lang = Translations.LOCALES[this.lang];
    }
    if (!this.lang) {
      this.lang = 'en-us';
    }
    this.translation = Translations.TRANSLATIONS[this.lang] || Translations.TRANSLATIONS['en-us'];
  }

  // given a key and optional map of variable/values, return a localized string
  get(key: string, params: any = {}) {
    let result = this.translation[key] || Translations.TRANSLATIONS['en-us'][key] || key;
    while (params) {
      // If result is 'this is a {{ sample }} text'
      // then match[0] = pattern
      // match[1] = 'this is a '
      // match[2] = '{{';
      // match[3] = ' sample '
      // match[4] = '}}'
      // match[5] = ' text'
      // from this we can substitute a value from data, if given
      const matches = result.match(/(.*)(\{\{)([^\}]+)(\}\})(.*)/);
      if (!matches) {
        break;
      }
      const variable = matches[3].trim();
      result = matches[1] + params[variable] + matches[5];
    }

    return result;
  }
}



/*
 * Copyright 2020-2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

import { SessionService } from './sessionService';
import { PersistentStateService } from './persistentStateService';
import { get, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import {
  DEFAULT_AUTHORIZATION_ERROR,
  SESSION_LIMIT_EXCEEDED_ERROR,
  TOO_MANY_SESSIONS,
  UNAUTHORIZED
} from './constants';
import { take } from 'rxjs/operators';

const STATION_REGION_COOKIE = 'zero.session.region';
const ZERO_SESSION_COOKIE = 'zero.session.hammer-token';
const CENTRAL_LOGIN_COOKIE = 'central-login';
const REDIRECT_TO_COOKIE = 'redirect_to';
const COOKIE_EXPIRY = 'Thu, 01 Jan 1970 00:00:00 GMT';

export class AuthenticationService {
  private sessionService: SessionService;
  private persistentStateService: PersistentStateService;

  public responseSubject: Subject<string>;
  private isInLogin = false;

  constructor(sessionService: SessionService) {
    this.sessionService = sessionService;
    this.persistentStateService = new PersistentStateService();
    this.responseSubject = new Subject();
  }

  public getResponseSubject() {
    return this.responseSubject;
  }

  async login(params: any) {
    return await this.sessionService.login(params);
  }

  loginWithToken(token: string, tokenSource: string) {
    if (!this.isInLogin) {
      this.isInLogin = true;
      this.sessionService.loginWithJwtToken(token, tokenSource)
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.setSecureCookie();
            this.isInLogin = false;
            this.navigateToLoggedInApp();
          },
          error: (response) => {
            this.isInLogin = false;
            if ((isEqual(response.status, 403) && (get(response, 'data.error.code') === TOO_MANY_SESSIONS))) {
              this.responseSubject.error(SESSION_LIMIT_EXCEEDED_ERROR);
            } else if (response.status === 400) {
              this.responseSubject.error(UNAUTHORIZED);
            } else {
              this.responseSubject.error(DEFAULT_AUTHORIZATION_ERROR);
            }
          }
        });
    }
  }

  private getCheckAuth() {
    const data = this.sessionService.getCache();
    if (data && data.contexts) {
      if (this.sessionService.hasContext(data.contexts, 'partner')) {
        return '/partner/check-auth';
      } if (this.sessionService.hasContext(data.contexts, 'customer_estate')) {
        return '/enterprise/check-auth';
      }
    }

    return '/check-auth';
  }

  /*
   * The way this works is we have already saved a "redirect_to" cookie
   * which is read by check-auth. We here load CDB/PDB/EDB and go immediately
   * to check-auth which does standard one-time-on-login stuff and
   * which *then* uses the forward-to cookie value to take the user to the
   * page they wanted.
   */
  navigateToLoggedInApp() {
    const redirectLocation = '/manage' + this.getCheckAuth();
    this.getWindowLocation().replace(redirectLocation);
  }

  getWindowLocation() {
    return window.location;
  }

  setCookie(name: string, value: string) {
    // Note, MDN recommends using encodeURIComponent() over the (deprecated) encode() method
    // the shared lib authenticationService reads this cookie value using ngx-cookie-service
    // which itself uses decodeURIComponent()
    // (see https://www.npmjs.com/package/ngx-cookie-service?activeTab=code)
    document.cookie = name + '=' + encodeURIComponent(value) + '; domain=' + this.sessionService.getCookieDomain() + '; path=/;';
  }

  private clearSecureCookie() {
    document.cookie = `${CENTRAL_LOGIN_COOKIE}=; expires=${COOKIE_EXPIRY}; domain=${this.sessionService.getCookieDomain()}; path=/;`;
    document.cookie = `${ZERO_SESSION_COOKIE}=; expires=${COOKIE_EXPIRY}; domain=${this.sessionService.getCookieDomain()}; path=/;`;
    document.cookie = `${STATION_REGION_COOKIE}=; expires=${COOKIE_EXPIRY}; domain=${this.sessionService.getCookieDomain()}; path=/;`;
  }

  private setSecureCookie() {
    this.clearSecureCookie();

    const headers = this.sessionService.getCache();

    const hammerToken = get(headers, 'token');
    if (hammerToken) {
      this.setCookie(ZERO_SESSION_COOKIE, hammerToken);
    }

    const region = get(headers, 'region');
    if (region) {
      this.setCookie(STATION_REGION_COOKIE, region);
    }

    this.setCookie(CENTRAL_LOGIN_COOKIE, 'true');
  }

  /**
   * @returns a string that can be appended to /manage to form the URL to forward to
   *   for example: if cookie is redirect_to=/threat-analysis-center/dashboard
   *   then returns '/threat-analysis-center/dashboard'
   * If not empty, the result is guaranteed to start with '/'
   */
  private getRedirectCookie(): string {
    const prefix = REDIRECT_TO_COOKIE + '=';
    const cookies = document.cookie.split(';');
    let result = cookies.find((row) => row.trim().startsWith(prefix)) || '';
    if (result) {
      result = result.split('=')[1];
      if (result && result.charAt(0) !== '/') {
        result = '/' + result;
      }
    }
    return result;
  }

  public deleteRedirectCookie(): void {
    document.cookie = `${REDIRECT_TO_COOKIE}=; domain=${this.sessionService.getCookieDomain()}; path=/; secure=true; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }

  public getAndDeleteRedirectCookie(): string {
    const redirectTo = this.getRedirectCookie();
    if (redirectTo) {
      this.deleteRedirectCookie();
    }
    return redirectTo;
  }
}

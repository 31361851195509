/*
 * Copyright 2023 Sophos Limited. All rights reserved.
 *
 * 'Sophos' and 'Sophos Anti-Virus' are registered trademarks of Sophos Limited
 * and Sophos Group.  All other product and company names mentioned are
 * trademarks or registered trademarks of their respective owners.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Login } from './Login';

const root = ReactDOM.createRoot(document.getElementById('root') as any as Element);
root.render(
  <React.StrictMode>
    <Login />
  </React.StrictMode>
);
